import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/rows/hero"
import Intro from "../components/rows/intro"
import ImgSection from '../components/rows/imgsection'
import Outro from "../components/rows/outro"

import Banner from "../images/img/hero/products-sizes-capacity-hero.jpg"

export default function Products({ data }) {

  const tableHeaders = [
    {
      id: 1,
      header: "Model",
    },
    {
      id: 2,
      header: "Drying Capacity",
    },
  ]
  const tableRows = data.allStrapiTables.edges[0].node.new_row

  return (
    <Layout>
      <Seo title="Products. Sizes. Capacity." />
      <Hero title="Products. Sizes. Capacity." banner={Banner} />
      {data.allStrapiProducts.edges.map(({ node }) => (
        <>
          <Intro
            key={node.products_intro.id}
            title={node.products_intro.title}
            subtitle={node.products_intro.subtitle}
            description={node.products_intro.description}
          />
          <ImgSection
            key={node.products_img.id}
            buttonwhite
            subtitle={node.products_img.subtitle}
            title={node.products_img.title}
            description={node.products_img.description}
            tableHeaders={tableHeaders}
            tableRows={tableRows}
            link={node.products_img.button_title}
            href={node.products_img.button_link}
          />
          <Outro
            key={node.products_outro.id}
            subtitle={node.products_outro.subtitle}
            title={node.products_outro.title}
            link={node.products_outro.button_title}
            href={node.products_outro.button_link}
          />
        </>
      ))}
    </Layout>
  )
}

export const query = graphql`
query Products {
  allStrapiTables {
    edges {
      node {
        new_row {
          id
          model
          drying_capacity
        }
      }
    }
  }
  allStrapiProducts {
    edges {
      node {
        products_intro {
          id
          subtitle
          title
          description
        }
        products_img {
          id
          subtitle
          title
          description
          button_title
          button_link
        }
        products_outro {
          id
          subtitle
          title
          button_title
          button_link
        }
      }
    }
  }
}
`